<!--
 * @Description: 
 * @Author: transparent
 * @Date: 2021-12-31 11:17:14
 * @LastEditTime: 2022-02-16 17:47:17
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/views/systemSetting.vue
-->
<template>
  <div class="system-setting">
    <el-tabs :tab-position="tabPosition" style="height: 100%">
      <el-tab-pane label="版本 Release Note">
        <!-- <el-input
          type="textarea"
          :autosize="{ minRows: 10, maxRows: 60 }"
          placeholder="请输入内容"
          v-model="textarea"
          show-word-limit
        >
        </el-input> -->
        <mavon-editor
        class="markdown-body"
            ref="md"
            placeholder="请输入文档内容..."
            :boxShadow="false"
            style="z-index:1;border: 1px solid #d9d9d9;height:75vh"
            v-model="textarea"
            :toolbars="toolbars"
            :ishljs="true"
          />
        <el-row type="flex" justify="end" class="releaseButton">
          <el-button type="primary" @click="updateSystemSettingEdition">提交</el-button>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Vue from "vue";
import {
  getSystemSettingEdition,
  updateSystemSettingEdition,
} from "@/api/systemSetting";
import { JSONEditor } from "@/common/jsoneditor";

//编辑器
import mavonEditor from "mavon-editor";
// import "mavon-editor/dist/css/index.css" 
// import 'mavon-editor/dist/markdown/github-markdown.min.css'
Vue.use(mavonEditor);
export default {
  name: "systemSetting",
  data() {
    return {
      tabPosition: "left",
      textarea: null,
      setting: {},
      toolbars: {
        bold: true, // 粗体
        italic: true, // 斜体
        header: true, // 标题
        underline: true, // 下划线
        strikethrough: true, // 中划线
        mark: true, // 标记
        superscript: true, // 上角标
        subscript: true, // 下角标
        quote: true, // 引用
        ol: true, // 有序列表
        ul: true, // 无序列表
        link: true, // 链接
        // imagelink: true, // 图片链接
        code: true, // code
        table: true, // 表格
        fullscreen: true, // 全屏编辑
        readmodel: true, // 沉浸式阅读
        htmlcode: true, // 展示html源码
        help: true, // 帮助
        /* 1.3.5 */
        undo: true, // 上一步
        redo: true, // 下一步
        trash: true, // 清空
        save: false, // 保存（触发events中的save事件）
        /* 1.4.2 */
        navigation: true, // 导航目录
        /* 2.1.8 */
        alignleft: true, // 左对齐
        aligncenter: true, // 居中
        alignright: true, // 右对齐
        /* 2.2.1 */
        subfield: true, // 单双栏模式
        preview: true // 预览
      }
    };
  },

  created() {
    this.getSystemSettingEdition();
  },

  watch: {},

  mounted() {},

  methods: {
    getSystemSettingEdition() {
      getSystemSettingEdition().then((response) => {
        this.textarea = response.data;
        this.$store.commit("setSystemInfo", response.data);

      });
    },
    updateSystemSettingEdition() {
      let data = {
        key: "system_setting_release_notes",
        value: this.textarea,
      };
      updateSystemSettingEdition(data).then((response) => {
        if (response.success) {
          this.$message({ type: "success", message: "更新成功!" });
          this.getSystemSettingEdition()

        } else {
          this.$message({
            type: "info",
            message: response.message,
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.system-setting {
  .releaseButton{
    margin: 20px;
  }
}
</style>
 
